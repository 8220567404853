.CaptureServicePopup {
  left: 0%;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  position: fixed;
  display: flex;
  z-index: 999;
  padding: 5rem;
}

body:has(.CaptureServicePopup) {
  overflow: hidden;
}

.CaptureServicePopup .overlay {
  position: fixed;
  background-color: #00000050;
  z-index: -1;
  display: flex;
  left: 0%;
  top: 0;
  right: 0;
  bottom: 0;
}

.CaptureServicePopup > div:not(.overlay) {
  background: #fff;
  padding-bottom: 10rem;
  max-width: 1400px;
  width: 100%;
  border-radius: 2rem;
  margin: auto;
}

.Réserver_Solutions {
  padding: 30px 26px;
  border-radius: 40px;
  background-color: #f9fafb;
  width: 100%;
}

.Réserver_Solutions .container {
  margin-top: 20px;
  max-width: 500px;
  margin-left: 0;
}

.Réserver_Solutions .container h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
}

.Réserver_Solutions .container p {
  font-size: 17.47px;
  font-weight: 400;
  line-height: 26.2px;
  margin-top: 20px;
}

.Réserver_Solutions .container h5 {
  margin-top: 14px;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
}

.Réserver_Solutions .line {
  border-bottom: solid 1px #e3e3e3;
  width: 100%;
}

.Réserver_Solutions .item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.Réserver_Solutions .item-container .right {
  /* width: 300px; */
  min-width: 240px;
  flex: 1;
  /* position: sticky; */
  bottom: 0;
  height: fit-content;
  /* margin-top: auto; */
  margin-left: 0;
  margin-bottom: 0;
  max-width: 100%;
  padding: 20px;
}

.Réserver_Solutions .right > button {
  max-width: 100%;
}

.Réserver_Solutions .add.white {
  background-color: white;
  color: #000000;
}

.Réserver_Solutions .item-container .right h4 {
  font-size: 18px;
  margin-bottom: 25px;
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 7px;
}

.Réserver_Solutions .item-container .right h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.Réserver_Solutions .item-container .right .paragraph {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-top: 10px;
}

.Réserver_Solutions .item-container .right .paragraph li {
  margin-top: 10px;
  margin-left: 20px;
  list-style: outside;
}

.Réserver_Solutions .flex-upload {
  /* flex-direction: column; */
  position: relative;
  gap: 20px;
  flex-wrap: wrap;
}

.Réserver_Solutions .flex-upload button {
  background-color: red;
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 16px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Réserver_Solutions .flex-upload {
  width: 100%;
}

.Réserver_Solutions .flex-upload img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 20px;
}

.Réserver_Solutions .flex-upload .upload-button {
  width: 150px;
  height: 150px;
  border: dashed 1px #377dff;
  border-radius: 20px;
}

.Réserver_Solutions .flex-upload .upload-button img {
}

.Réserver_Solutions .item-container .right .add {
  width: 40rem;
  height: 6.6rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #377dff;
  color: white;
  font-size: 2rem;
  position: absolute;
  bottom: -13rem;
  left: 50%;
  transform: translateX(-50%);
}

.Réserver_Solutions {
  margin: auto;
  margin-bottom: 10rem;
  position: relative;
}

.Réserver_Solutions * {
  font-size: 1.6rem;
}

@media (max-width: 560px) {
  .CaptureServicePopup {
    padding: 0;
    border-radius: 0;
  }
  .app-body:has(.Réserver_Solutions) {
    padding: 0;
  }

  .CaptureServicePopup > div:not(.overlay) {
    border-radius: 0;
  }

  .Réserver_Solutions {
    padding: 0;
    border-radius: 10px;
    padding: 20px;
    padding-top: 10px;
    border-radius: 20px;
  }

  .Réserver_Solutions .container {
    padding: 20px;
    padding-top: 0;
  }

  .Réserver_Solutions .tabs-container {
    background-color: transparent;
    margin-bottom: 0;
    width: 100%;
    margin: auto;
    overflow: visible;
  }

  .Réserver_Solutions .item-container {
    justify-content: center;
  }

  .Réserver_Solutions .item-container .right {
    margin: 0;
    position: relative;
    padding: 0;
    width: 100%;
  }

  .Réserver_Solutions .tabs-container {
    flex-wrap: wrap;
  }
}
