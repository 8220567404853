.Food{
    
}

.Food .top{
    padding: 4.3rem 6.8rem;
    gap: 7rem;
    row-gap: 3rem;
    display: flex;
    flex-wrap: wrap;
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(5px);
    background: #ffffff6d;
    border-radius: 5rem;
    border: solid 1px #5551;
    max-width: 90%;
    margin: auto;
}



.Food .top>div{
    flex: 2;
    min-width: 200px;
}
.Food .top>div:last-child{
    flex: 4;
}
.Food h4{
    font-size: 1.8rem;
    white-space: nowrap;

    
}

.Food h4::after{
        content: '';
        width: 7rem;
        height: 0.4rem;
        background-color: black;
        display: flex;
        margin: 1rem 0;
}
.Food .top p{
    font-size: 1.5rem;
}

.Food .images{
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    margin-top: -12rem;
}
.Food .images img{
    border-radius: 2.5rem;
    flex: 1;
    height: 37rem;
    object-fit: cover;
    width: 30%;
    min-width: 300px;
    filter: grayscale(0.8);
}
.Food .images img:first-child{
    width: 100%;
    height: 56rem;
    min-width: 100%;
    filter: none;
}

.Food>img{
    margin-top: 7rem;
    width: 100%;
    min-height: 3rem;
    object-fit: cover;
    object-position: center;
}

.Food>h2{
    margin-top: 7rem;
    text-align: center;
    font-size: 5rem;
}

.Food>p{
    margin-top: 7rem;
    text-align: center;
    font-size: 2.5rem;
}

.Food>button{
    margin: auto;
    margin-top: 7rem;
    display: flex;
}

@media (max-width:1000px) {
    .Food .top{
        flex-direction: column;
    }
}

@media (max-width:560px) {
    .Food .top>div{
        min-width: auto;
    }

    .Food>h2{
        font-size: 2.5rem;
    }

    .Food p{
        font-size: 1.6rem;
    }
}