.PackComponent {}

.PackComponent .shrp-pack {
  /* clip-path:polygon(60% 0,100% 0, 100% 100%,  50% 100%, 0 65%);
    shape-outside: polygon(60% 0, 100% 0, 100% 100%, 50% 100%, 0 65%); */
  /* border-radius: 10px; */
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 29rem;
  position: relative;
}



.PackComponent .shrp-pack img.sharp-img {
  height: 100%;
  width: 100%;
}

.PackComponent button {
  font-size: 3rem;

  width: 30rem;
}


.PackComponent .shrp-pack p {
  font-size: 9rem;
}

.PackComponent .shrp-pack p>span {
  margin-top: -2rem;
  display: flex;
  font-size: 3rem;
  margin-left: auto;
  width: 100%;
  justify-content: flex-end;
}

.PackComponent .shrp-pack img{
width: 100%;
object-position: left;
object-fit: contain;
height: 2.6rem;
}

.PackComponent .shrp-pack .item {
  width: 100%;
  min-height: 100%;
  /* min-width: 260px; */
  border-radius: 28px 0 28px 28px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.PackComponent .shrp-pack .item .top {
  display: flex;
}

.PackComponent .shrp-pack .item .top div:first-child {

  background-color: #FFF;
  border-radius: 28px;
  box-shadow: 100px 30px black;
  padding: 2rem;
  color: black;
  font-size: 23.51px;
  font-weight: 400;
  line-height: 28.91px;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
}

.PackComponent .shrp-pack .item .top div:last-child {
  border-radius: 28px 28px 0 0;

  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 60px;
}

.PackComponent .shrp-pack .item .body{
  width: 100%;
  height: 200px;
  background-color: black;
  border-radius: 28px 0 28px 28px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  gap: 2rem;
  margin-top: -1px;
  flex: 1;
  /* border-top: solid 4px #000; */
}



.PackComponent h2 {
  font-size: 2.4rem;
}

.PackComponent li {
  font-size: 1.6rem;
  margin-top: 1rem;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.PackComponent .left-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PackComponent li::before {
  content: '';
  height: 1.9rem;
  background-size: contain;
  background-repeat: no-repeat;
  width: 1.9rem;
  min-width: 1.9rem;
  background-image: url('../../../public/images/check.svg');
}

@media (max-width: 920px) {
  .PackComponent {
    margin: auto;
    flex-direction: column;
  }

  .PackComponent>div:first-child {
    width: 100%;
    flex-direction: column;
  }

  .PackComponent h2 {
    padding: 2rem 0;
    font-size: 3rem;
  }

  .PackComponent>div:first-child>div {
    width: 100%;
  }



  .PackComponent li {
    width: 100%;
  }

  .PackComponent button {
    width: 100%;
    height: 15rem;
  }

  .PackComponent .shrp-pack img:last-child {
    height: 6rem;
    left: 2rem;
    bottom: 2rem;
  }
}