.Steps{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 666px;
    align-items: center;
    z-index: -1;
}

.Steps svg {
    font-size: 20px;
}

.Steps .title{
margin-bottom: 2rem;
margin-top: 4rem;
color:  #4E4E4E;
font-size: 1.6rem;

}

.Steps .list{
    margin-bottom: 4rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.Steps .list .line{
    height: 1px;
    width: calc(100% - 60px);
    background-color: #D9D9D9;
    position: absolute;
    left: 50%;
    top: 22px;
    transform: translate(-50%,-50%);
    overflow: hidden;
}
.Steps .list .line span{
    display: flex;
    width: 50%;
    background-color: #1879FE;
    height: 100%;
}
.Steps li{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #4E4E4E;
    width: 60px;

}
.Steps li button{
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    background-color: white;
    border-radius: 23px;
    width: 30px;
}
.Steps li.active button{
    background-color: #1879FE;
    color: white;
    width: 60px;
  
}

.Steps li.active {
    color: #1879FE;
}

.Steps li button svg{
    font-size: 20px;
}

.Steps .title span{
    font-weight: 700;
}

@media (max-width:560px) {
    .Steps li button{
        width: 46px !important;
        height: 46px;
    }
}