.StepsCapt video {
  margin-top: 7rem;
  width: 100%;
  max-height: 80rem;
  border-radius: 6px;
}

.StepsCapt .espaces {
  display: flex;
  margin-top: 50px;
  width: 100%;
}

.StepsCapt .espaces h1 {
  font-size: 5rem;
  font-weight: 500;
}

.StepsCapt .espaces h1 span {
  padding-left: 6rem;
}

.StepsCapt .espaces p {
  font-size: 22px;
  margin-top: 2.5rem;
}

.StepsCapt .espaces img {
  display: flex;
  flex: 1;
  height: 600px;
  min-width: 50%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  transform: rotateY(-180deg);
  object-fit: contain;
}

.StepsCapt .espaces div div {
  border-left: 0.6rem solid #000;
  padding-left: 3.3rem;
  position: relative;
}

.StepsCapt .espaces div div::before {
  content: url('../../../public/images/start.svg');
  position: absolute;
  left: -5rem;
  top: 10rem;
}

.StepsCapt .espaces div button {
  margin-top: 3rem;
}

@media (max-width: 920px) {
  .StepsCapt .espaces {
    flex-direction: column-reverse;
    align-items: center;
  }

  .StepsCapt .espaces img {
    max-width: min(400px, 100%);
    height: 400px;
    flex: none;
  }
}
