.Footer {
  padding: 10rem 0 2rem;
  background-color: #ffff;
}

.Footer .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.Footer .container > div {
  flex: 1;
  min-width: 200px;
  min-width: fit-content;
}
.Footer .container > div:last-child {
  flex: 2;
}

.Footer .container .App-logo {
  height: 5.8rem;
}

.Footer .socio {
  display: flex;
  gap: 56px;
  margin-top: 3.2rem;
  align-items: center;
}

.Footer .socio a {
  color: black;
}

.Footer .socio a:hover {
  color: #1576fe;
}
.Footer .socio img {
  height: 3.3rem;
  width: 3.3rem;
}
.Footer .reserve {
  margin-top: 8rem;
  width: 25rem;
}

.Footer .container > div:nth-child(2) img {
  width: 12.7rem;
  margin: auto;
}

.Footer h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  margin-bottom: 2.5rem;
}

.Footer a {
  display: flex;
  gap: 1rem;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  margin-top: 1.3rem;
  align-items: center;
}

.Footer a img {
  height: 2rem;
  width: 2rem;
}
.Footer .flex {
  align-items: center;
  gap: 3.9rem;
  margin-top: 10rem;
}
.Footer .flex img {
  height: 8.9rem;
}

.Footer .links {
  display: flex;
  gap: 7rem;
}

.Footer-end {
  background-color: black;
  /* margin-top: 7rem; */
  display: flex;
}

.Footer-end .container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  color: #ffff;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
}

.Footer-end img {
  height: 5.4rem;
}
@media (max-width: 560px) {
  .Footer .container {
    gap: 4rem;
  }
  .Footer .container > div:nth-child(2) {
    display: flex;
  }

  .Footer .links {
    flex-direction: column;
    gap: 1rem;
  }

  .Footer .flex {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    row-gap: 1rem;
  }
  .Footer .reserve {
    width: 100%;
    margin-top: 4rem;
  }

  .Footer-end .container {
    flex-direction: column;
    gap: 2rem;
  }
}
