.Services h2 {
  margin-top: 7rem;
  font-size: 6rem;
  font-weight: 900;
  line-height: 7rem;
  text-align: center;
}

.Services h2 span {
  font-family: "Pacifico";
  font-weight: 400;
}

.Services .card {
  display: flex;
  gap: 7rem;
  align-items: stretch;
  margin-top: 7rem;
}

.Services .card.c-2 {
  flex-direction: row-reverse;
}

.Services .card img {
  width: 55%;
  max-height: 47rem;
  border-radius: 1.6rem;
}

.Services .card h3 {
  font-family: "ClashDisplay-Variable";
  font-size: 4rem;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.Services .card p {
  font-size: 2rem;
}

.Services > img {
  width: 100%;
  max-height: 80rem;
  border-radius: 1.6rem;
  margin-top: 7rem;
}

.Services .shrp-text {
}

.Services .shrp-text p {
  /* display: flex; */
}

.Services .shrp-text div::before {
  content: "";
  /* clip-path:polygon(60% 0,100% 0, 100% 100%,  50% 100%, 0 65%); */
  shape-outside: polygon(60% 0, 100% 0, 100% 100%, 50% 100%, 0 65%);
  float: right;
  width: 70%;
  height: 50rem;
  /* shape-outside: url(../../../public/images/iPhone.png); */
  background-image: url(../../../public/images/iPhone.png);
  background-size: contain;
  background-position: center;
  background-size: 85%;
  background-repeat: no-repeat;
  /* background-color: red */
}

.Services .shrp-text h3 {
  font-size: 4rem;
  white-space: wrap;
}

.Services .shrp-text p {
  text-align: justify;
}

.Services .shrp-text img {
  display: none;
}

.Services .formulaire {
  padding: 7rem 9rem;
  background: #f9fafb;
  border-radius: 3.2rem;
  margin-top: 7rem;
  box-shadow: 24px 30px 84px 0px #00000014, 94px 120px 152px 0px #00000012,
    212px 270px 206px 0px #0000000a, 376px 480px 244px 0px #00000003,
    588px 750px 267px 0px #00000000;
}

.Services .formulaire video {
  border-radius: 0.6rem;
  width: 100%;
}

.Services .users {
  display: flex;
  margin-top: 7rem;
  gap: 7rem;
  justify-content: center;
  flex-wrap: wrap;
}

.Services .users > div {
  height: 38rem;
  width: 34rem;
  border-radius: 0.9rem;
  background-color: #f9fafb;
  overflow: hidden;
  position: relative;
}
.Services .users > div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}
.Services .users > div > div {
  display: flex;
  height: 7rem;
  padding: 1.2rem;
  justify-content: space-between;
  color: white;
  background-color: #1576fe;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

.Services .users > div > div h4 {
  font-size: 1.8rem;
  font-family: "Lufga Medium";
}

.Services .users > div > div p {
  font-size: 1.6rem;
}

.Services .users > div > div img {
  height: 4.6rem;
  width: 4.6rem;
  border: solid 1px #eee;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  background-color: white;
}

@media (max-width: 920px) {
  .Services .card {
    flex-direction: column !important;
  }

  .Services .card img {
    width: 100%;
  }

  .Services .shrp-text {
    display: flex;
    flex-direction: column;
  }

  .Services .formulaire {
    padding: 4rem 5rem;
  }
}

@media (max-width: 720px) {
  .Services .users > div {
    width: 42rem;
  }

  .Services .shrp-text div::before {
    display: none;
  }

  .Services .shrp-text img {
    display: flex;
    max-width: 400px;
    margin: auto;
  }

  .Services .formulaire {
    padding: 2rem 3rem;
  }
}
