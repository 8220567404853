.Popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background-color: #0003;
  z-index: 99;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Popup .Card {
  background-color: white;
  padding: 5rem 3rem;
  max-width: 90rem;
  max-height: 57rem;
  position: relative;
  overflow: auto;
  border-radius: 1rem 1rem 0 0;
}

.Popup .Card .flex img {
  height: 14rem;
  width: 14rem;
}

.Popup .Card .flex {
  display: flex;
  gap: 3rem;
  margin-bottom: 2rem;
}

.Popup h3 {
  font-size: 3.2rem;
  font-weight: 500;
}

.Popup span {
  font-size: 2.2rem;
  font-weight: 500;
  color: #444444;
  margin: 1rem 0;
  display: flex;
}

.Popup .Card button {
  height: 5rem;
  width: 18rem;
  display: flex;
  background-color: black;
  border-radius: 0.6rem;
  color: white;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Popup .Card p {
  font-size: 1.6rem;
}

.Popup .Card strong {
  font-size: 1.6rem;
}

.Popup .end-footer {
  /* position: sticky; */
  bottom: 0;
  background-color: black;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  max-width: 90rem;
  min-height: 9rem;
  display: flex;
  border-radius: 0 0 2rem 2rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  align-items: flex-start;
  gap: 2rem;
  align-items: center;
}

.Popup .end-footer p {
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
}

.Popup .end-footer button {
  border-radius: 1.6rem;
  border: 1px solid #ffffff;
  height: 5rem;
  padding: 0 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Popup .end-footer .buttons {
  gap: 2rem;
  display: flex;
}

.Popup .end-footer .buttons button:last-child {
  background-color: #1879fe;
  gap: 1rem;
  border: none;
  width: 22rem;
  padding: 0;
}

.Popup a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Popup a span {
  text-decoration: underline;
}

.Popup a svg {
  font-size: 16px;
}

.Popup .overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.Popup .buttons button.active {
  background-color: #555 !important ;
  color: #0f0;
}

.Popup .buttons button.active svg {
  font-size: 2.2rem;
  animation: move 0.4s;
  background-color: #0f0;
  border-radius: 50%;
}

@keyframes move {
  0% {
    transform: translateX(-10px) rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 560px) {
  .Popup .end-footer {
    flex-direction: column;
    padding: 2rem 2rem;
    align-items: stretch;
  }

  .Popup .end-footer .buttons {
    width: 100%;
  }

  .Popup .end-footer button {
    padding: 0 1rem;
    flex: 1;
  }
}
