.FAQ{
margin-top: 7rem;
margin-bottom: 7rem;
}

.FAQ h2{
    font-size: 5rem;
    margin-bottom: 7rem;
}

.FAQ button,.FAQ p{
color: #000000;
border-top: solid 3px  #D7DEF0;
min-height: 12rem;
width: 100%;
font-weight: bold;
font-size: 2rem;
text-align: left;
display: flex;
align-items: center;
justify-content: space-between;
gap: 3rem;
}

.FAQ p{
    font-size: 1.5rem;
padding: 2rem;
display: none;
background-color: white;

}

.FAQ div:last-child button{
    border-bottom: solid 3px  #D7DEF0; 
}

.FAQ  button.active~p{
    display: flex;

}



.FAQ  button.active img{
    transform: rotate(135deg);
}

.FAQ div button img{
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    transition: all 0.4s;
}

.FAQ >div>div{
    display: none;
}