body {
  background-color: #f2f2f2;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.App {
  min-height: 100dvh;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

button:active,
a:active {
  opacity: 0.5;
}

button:hover,
a:hover {
  filter: drop-shadow(10px 10px 20px #1576fe20);
}

.container {
  margin: auto;
  max-width: min(1100px, 90%);
}

.Hero {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 7rem;
  align-items: center;
  gap: 5rem;
}

.Hero > * {
  flex: 1;
}

.Hero > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.8rem;
}

.Hero h1 {
  font-size: 6rem;
  font-family: 'ClashDisplay-Variable';
}

.Hero p {
  font-size: 2rem;
}

.Hero > img {
  max-width: 45%;
  height: auto;
  display: flex;

  border-radius: 26px;
}

button.reserve {
  padding: 1em 2rem;
  background-color: #1576fe;
  color: white;
  border-radius: 99px;
  font-size: 1.8rem;
}

.Maps {
}

.inputs-label {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 20px;
}

.inputs-label .input-container {
  position: relative;
  width: 100%;

  border-radius: 10px;
  box-shadow: 0px 5.11px 20.18px 0px #00000008;
  border: solid 1px #dadada;
  padding: 0;
}

.inputs-label .input-container label {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 5px;
  transition: all 0.2s;
}

.inputs-label label {
  /* position: relative; */
  height: 2px;
  width: fit-content;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  top: 50%;
  color: #555;
}

.inputs-label input,
.inputs-label select {
  border: none !important;
  outline: none;
  height: 5.4rem;
  width: 100%;
  border-radius: 10px;
  display: flex;
  padding-left: 20px;
  margin: 0;
  font-size: 1.6rem;
  background-color: transparent;
}

.inputs-label .input-container:has(input:focus),
.inputs-label select:focus {
  border: #1576fe solid 1px;
  outline: none;
}

.inputs-label .input-container input:focus ~ label,
.inputs-label .input-container input:valid ~ label,
.inputs-label .input-container input:-webkit-autofill ~ label {
  position: absolute;
  left: 1rem;
  top: 0%;
  background-color: #f2f2f2;
  color: #1576fe;
}

.inputs-label .input-container .eye {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  color: #9a9a9a;
}

.inputs-label > button {
  min-height: 5.4rem;
}

.inputs-label .or {
  font-size: 1.6rem;
}

.flex-checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  /* margin: 2rem 0; */
  height: fit-content;
}

.flex-checkbox input {
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 0.2rem;
  overflow: hidden;
}

.flex-checkbox label {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
}

@media (max-width: 720px) {
  .Hero {
    flex-direction: column-reverse;
  }

  .Hero > img {
    width: 100%;
    max-width: 100%;
  }
}

/* comment */
