.Module {}

.Réserver_Solutions:has(.Module) {
    background-color: transparent;
}


.Réserver_Solutions:has(.Module) .tabs-container {
  
    display: none;
}

.Module h4 {
    border: 0.2rem solid #A0A0A0;
    width: 34rem;
    height: 6rem;
    gap: 0px;
    border-radius: 1.7rem;
    border: 2.37px 0px 0px 0px;
    max-width: 100%;
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Module .cards {
    padding: 4rem;
    display: flex;
    gap: 2.4rem;
    flex-wrap: wrap;
}

.Module .cards img {
    height: 5rem;
    width: 5rem;
}

.Module .cards h3 {
    font-size: 2rem;
    font-weight: 500;
    text-align: left;

}

.Module .Card {
    border: 0.98px solid #DADADA;
    padding: 2.4rem;
    border-radius: 1rem;
    flex: 1;
    cursor: pointer;
    min-width: 240px;


}

.Module .Card:active{
    background-color: #DADADA;
}

.Module .cards .flex {
    gap: 3rem;
    margin-bottom: 2rem;
}

.Module .Card p {
    max-width: 100%;
}

@media (max-width:560px) {
    .Module .cards {
        padding: 2rem 0;
    }

    .Module .Card {
        min-width: 100%;
    }

    .Module .Card .flex {
        flex-direction: column;
    }
}