.header-container {
  position: fixed;
  top: -10rem;
  padding: 2rem 0;
  /* background-color: #F2F2F2; */
  margin-top: 1.2rem;
  z-index: 99;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  left: 0;
}

.header-container.active {
  top: 0;
  margin: 0;
}
@keyframes showHeader {
  0% {
    transform: translate();
  }
}

.Header {
  display: flex;
  align-items: center;
  height: 6.8rem;
  width: 820px;
  background-color: white;
  max-width: 100%;
  border-radius: 3.4rem;
  justify-content: space-between;
  margin: auto;
  padding: 1rem;
  position: relative;
  box-shadow: 24px 30px 84px 0px #00000014;
}

.Header > img {
  display: flex;
  height: 3rem;
  width: 16rem;
}
.Header menu {
  flex: 1;
}
.Header menu ul {
  display: flex;
  list-style: none;

  width: 100%;
}
.Header menu ul li {
  height: 5rem;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #494949;
  padding: 0 1rem;
}
.Header menu ul li a {
  position: relative;
}
.Header menu ul li a:hover {
  color: #1576fe;
}

.Header menu ul li a::after {
  width: 0%;
  height: 2px;
  background-color: #1576fe;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.Header menu ul li a:hover::after,
.Header menu ul li a.active::after {
  width: 100%;
}

.Header menu ul button {
  background: #1576fe;
  color: white;
  white-space: nowrap;
  height: 5rem;
  flex: auto;
  border-radius: 6.2rem;
}

.Header menu ul button a {
  display: flex;
  height: 100%;
  width: 100%;

  align-items: center;
  justify-content: center;
}
.Header menu ul > * {
  font-size: 2rem;
}
.menu-button {
  display: none;
}

.Header .overlay {
  display: none;
}

@media (max-width: 560px) {
  /* .Header{
        position: sticky;
        top: 10px;
    } */
  .Header menu {
    display: none;
    position: absolute;
    background-color: white;
    left: 0;
    top: 60px;
    width: 100%;
    padding: 40px 0;
    border-radius: 3.4rem;
  }
  .Header menu.active {
    display: flex;
  }
  .Header menu ul {
    flex-direction: column;
    margin: auto;
    align-items: center;
    gap: 2rem;
  }
  .Header menu ul > * {
    width: 16rem;
  }

  .menu-button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    /* transform: scale(1.2); */
  }
  .menu-button .bar {
    width: 20px;
    border-bottom: solid 2px #21252b;
    height: 2px;
    transition: all 0.4s;
  }
  .menu-button.active .bar {
    transform: rotate(-45deg);
  }
  .menu-button.active .bar:first-child {
    width: 50%;
    transform: translate(2px, 3.2px) rotate(45deg);
  }
  .menu-button.active .bar:last-child {
    width: 50%;
    transform: translate(9px, -4px) rotate(45deg);
  }

  .menu-button.active ~ .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #0003;
    z-index: -1;
    display: flex;
  }
}
