/* Request Form Styles */
.request-form {
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  width: 600px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 20px;
  /* border-radius: 0px; */
  flex-direction: column;
  gap: 0;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.form-container.fixed {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 999;
}

.form-container .request-form {
  margin: auto;
  position: relative;
  z-index: 99;
}
.form-container .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0005;
}
.request-form h4 {
  margin-bottom: 30px;
  text-align: left;
  color: #222;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
}
.request-form h4::after {
  background: linear-gradient(107.3deg, #6e38e0 13.39%, #ff5f36 77.64%);
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 100px;
  border-radius: 24px;

  height: 3px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-row .form-group {
  width: 48%;
}

.form-group {
  margin-bottom: 20px;
}

.request-form input,
.request-form textarea {
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px solid #222;
  box-sizing: border-box;
  font-size: 14px;
}

.request-form input:focus,
.request-form textarea:focus {
  border-bottom: #f55 1px solid;
  outline: none;
}

.request-form textarea {
  height: 100px;
  resize: none;
}

.request-form button {
  background-color: #1576fe;
  color: white;
  padding: 15px 40px;
  border: none;
  border-radius: 0px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  margin-left: auto;
  gap: 20px;
  align-items: center;
  border-radius: 60px;
}

.request-form button:hover {
  background-color: #222;
  opacity: 1;
}

.request-form button svg {
  font-size: 20px;
}

.request-form .error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

.request-form .close {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 20px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-row .form-group {
    width: 100%;
  }

  .request-form {
    padding: 7%;
  }

  .form-container .request-form {
    width: 500px;
  }
}

@media (max-width: 500px) {
  .form-container .request-form {
    height: 100%;
    border-radius: 0;
    width: 500px;
    justify-content: center;
  }
}
