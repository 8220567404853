.Conditions {
  margin-top: 18rem;
  margin-bottom: 14rem;
}

.Conditions h1 {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 6rem;
  font-weight: 500;
}

.Conditions p {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}
