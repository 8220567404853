.Réserver_Solutions:has(.Checkout) {
    background-color: #F9FAFB;
}


.Réserver_Solutions:has(.Checkout) .tabs-container {

    display: none;
}

.Réserver_Solutions .Checkout {
    background-color: white;
    padding: 4rem;
    border-radius: 2.7rem;
    max-width: 76rem;
    margin: auto;
}

.Réserver_Solutions .Checkout h1 {

    font-size: 2.4rem;

}

.Réserver_Solutions .Checkout {
    gap: 0;
    display: block;
}

.Checkout .line {
    border-bottom: solid 2px #ACACAC;
}

.Checkout .flex {
    margin-top: 3.6rem;
    gap: 1.6rem;
    width: 100%;
    flex-wrap: wrap;
}

.Checkout .flex img {
    height: 8rem;
    width: 8rem;
    object-fit: contain;
    object-position: center;
    box-shadow: 0px 2px 5px 0px #0000001A;
    padding: 0.8rem;
    border-radius: 0.5rem;
    /* box-shadow: 2px 9px 9px 0px #00000017;

box-shadow: 4px 20px 12px 0px #0000000D;

box-shadow: 7px 36px 15px 0px #00000003;

box-shadow: 11px 57px 16px 0px #00000000; */

}



.Checkout .flex>div:last-child {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.Checkout .flex h4 {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: left;
    color: #0A0D13;

}

.Checkout .flex span {
    color: #ACACAC;
    font-size: 1.8rem;
    font-weight: 400;
    text-align: left;

}

.Checkout .flex button {
    color: red;
}

@media (max-width:560px) {
    .Checkout .flex {
        position: relative;
    }

    .Checkout .flex div:has(img) {
        width: 100%;
    }

    .Checkout .flex button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1rem;
    }

    
}