.Maps{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 3.6rem;
    
}
.Maps h2{
    font-size: 4.6rem;
    font-weight: 600;
}   

.Maps h2 span{
    font-family: "Pacifico", cursive;
}

.Maps p{
    font-size: 2rem;
    max-width: 929px;
    line-height: 3.5rem;
}

.Maps video{
    width: 100%;
    height: auto;
    border-radius: 5.5rem;
    margin: 3.5rem 0 7rem;
}