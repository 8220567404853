@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Lufga Regular';
}

html {
  font-size: 9px;
}

@media (max-width: 1200px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 1080px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 7px;
  }
}

* {
  box-sizing: border-box;
}

/* @media (max-width:560px) {
  body{
    font-size: 8px;
  }
} */

/* input-container */

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
  /* justify-content: space-evenly; */
  /* margin-top: 11px; */
}

.input-container,
form .flex {
  max-width: 440px;
  flex: 1;
  display: flex;
  min-width: max(calc(50% - 21px), 200px);
}

.input-container {
  flex-direction: column;
  justify-content: flex-end;
}

form .flex.full {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.input-container.full {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.flex.full .input-container {
  min-width: 200px;
  max-width: 100%;
}

.input-container label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-container input,
.input-container select,
.input-container textarea,
.input-container .check-input {
  width: 100%;
  height: 46px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #e4e7eb;
  background-color: white;
  padding: 10px 13px;
  margin-top: 8px;
  box-shadow: none;
}

.input-container input.error,
.input-container select.error,
.input-container textarea.error,
.input-container .check-input.error {
  border: solid 1px #dc3545 !important;
}

.input-container span.error {
  color: #dc3545 !important;
  font-size: 10px;
  display: flex;
  min-width: 100%;
  height: 20px;
  overflow: hidden;
}

.input-container textarea {
  min-height: 80px;
}

form .flex {
  gap: 20px;
  flex-wrap: wrap;
}

.bold {
  font-weight: bold !important;
}

.checkboxs {
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  gap: 10px;
}

.check-box {
  display: flex;
  gap: 10px;
  position: relative;
  overflow: hidden;
  align-items: center;
  min-width: 120px;
}

.check-box .out {
  border: solid 1px #dfe4ea;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  color: white;
}

.check-box input:checked ~ .out {
  border: none;
  background-color: #3758f9;
}

.check-box input:checked ~ .out svg {
  /* display: none; */
}

.check-box input {
  min-width: 100px;
  height: 100px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.check-box svg {
  font-size: 20px;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.users {
  display: flex;
  justify-content: center;
  margin-top: 11px;
}

.users .user {
  width: 30px;
  display: flex;
  overflow: visible;
}

.users .user img {
  min-width: 46px;
  height: 46px;
  border-radius: 50%;
}

.users .user .items {
  min-width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #8ab1fc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Outfit;
  font-size: 19.88px;
  font-weight: 400;
  line-height: 22.94px;
  text-align: right;
  color: #ffffff;
}
