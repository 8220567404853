.Success-popup{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0005;
    z-index: 99;
}

.Success-popup .container{
    background-color: white;
    padding: 4rem;
    margin: auto;
    border-radius: 1.2rem;
    max-width: min(90% , 50rem);
}

.Success-popup .container h1{
    display: flex;
font-size: 3.4rem;
font-weight: 600;
text-align: left;
align-items: center;
gap: 4px;

}

.Success-popup .container h1 svg{
 height: 3.4rem;
 width: 3.4rem;   
 color: #377DFF;

}

.Success-popup p{
font-size: 1.6rem !important;
font-weight: 400;
text-align: left;
margin-top: 1.6rem;
}

.Success-popup button,.Success-popup a{
    margin-top: 3.2rem;
    margin-left: auto;
    display: flex;
    height: 5.6rem;
    background-color: #282828;
    color: white ;
    padding: 0 2.8rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.6rem;
    width: fit-content;
}